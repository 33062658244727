@use '@angular/material' as mat;
@import 'variables';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@include mat.checkbox-density(-1);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$gps-pink: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #E72264, /* This is the only change from mat-pink */
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);
$gps-app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$gps-app-accent:  mat.m2-define-palette($gps-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gps-app-warn:    mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
// $gps-app-theme: mat.m2-define-light-theme($gps-app-primary, $gps-app-accent, $gps-app-warn);
$gps-app-theme: mat.m2-define-light-theme((
  color: (
    primary: $gps-app-primary,
    accent: $gps-app-accent,
    warn: $gps-app-warn,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0,
 ));

//  Override some variables
:root {
  --mdc-list-list-item-supporting-text-color: $text; 
  --mdc-dialog-supporting-text-color: $text; 
}
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($candy-app-theme);
@mixin gps-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  // Override font for all components
  $custom-typography: mat.m2-define-typography-config(
    $font-family: 'Selawik, sans-serif',
  );
  
  * {
    letter-spacing: normal;
  }
  
  @include mat.all-component-typographies($custom-typography);

  .mat-app-background {
    background-color: #e0e0e0;
  }

  // Spacing above cards, and within them
  .mat-mdc-card {
    padding: 16px;
  } 
  .mat-mdc-card-content {
    padding: 0 !important;
  }

  // Form fields shouldn't be rounded off
  .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
    border-radius: 0 !important;
  }

  // Buttons should be rounded off, and should be bold
  .mat-mdc-unelevated-button.mat-mdc-unelevated-button { border-radius: 0; font-weight: 600; }
  .mat-mdc-outlined-button.mat-mdc-outlined-button { border-radius: 0; font-weight: 600; }

  // Stroked buttons should have black text and colored border
  .mat-mdc-outlined-button.mat-stroked-button.mat-accent {
    color: $text;
    border-color:  mat.m2-get-color-from-palette($gps-app-accent);
  }

  // Form fields on dark background should have white text
  .mat-mdc-form-field.dark-background .mat-mdc-text-field-wrapper {
    background-color: white;
  }

  // Slide toggle
  .mat-mdc-slide-toggle label {
    font-weight: bold !important;
    text-transform: uppercase;
    letter-spacing: normal !important;
    color: $link-dark;
  }

  //Checkbox labels
  .mat-mdc-radio-button label,
  .mat-mdc-checkbox label {
    font-size: 16px;
    color: $text;
  }
  
  //Checkbox size
  .mat-mdc-checkbox {
    --mdc-checkbox-state-layer-size: 16px;
  
    .mat-mdc-checkbox-ripple,
    .mdc-checkbox__ripple {
      top: -12px;
      left: -12px;
      right: -12px;
      bottom: -12px;
      display: none;;
    }
  
    label {
      padding-left: 6px !important;
    }
  }
  // Tooltips
  .mat-mdc-tooltip-panel.mat-mdc-tooltip-panel .mat-tooltip {
    font-size: 14px;
  }

  // Modals
  h1.mat-mdc-dialog-title {
    font-weight: 300;
    font-size: 34px;
    text-transform: uppercase;
  }

  .mat-mdc-dialog-content p {
    color: $text;
    font-weight: 300;
    font-size: 20px;
    margin-top: 0;
  }

  .mat-mdc-dialog-content h2 {
    color: $text;
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end;
  }

  // Don't show mat-icons in tree until font is loaded
  // This only applies to the tree, since that's the only place we use the icon font rather
  // than SVG icons
  :not(body.material-icons-loaded) .mat-icon-tree {
    display: none;
  }

  // Icons should be dark-link color by default
  .mat-icon.mat-icon-no-color {
    color: unset;
  }
  .mat-icon.icon-white {
    color: white;
  }

  // Icons have less space on right, unless they're in the tree
  .mat-icon.mat-icon:not(.mat-icon-tree) {
    margin-right: 8px;
  }

  // Special display for icons in teh tree
  .mat-icon.mat-icon.countryprofiletree__lefticon {
    svg {
      height: 16px !important;
      width: 16px !important;
    }
  }

  // Special display for a badge on a "new!" icon\
  .mat-icon.new .mat-badge-content {
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
    @media screen and (min-width: 1024px) {
      left: 85px !important;
      top: -8px;
    }
  }

  // Change vertical alignment and color of icons displayed as suffixes for form fields
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-suffix .mat-icon.mat-icon {
    color: #003A5C;
    position: relative;
    top: 3px;
  }

  // Menu width should not be limited
  .mat-mdc-menu-panel.mat-menu-panel {
    max-width: none;
  }

  .mat-mdc-menu-panel.mat-menu-panel .mat-nav-list {
    padding-top: 0;
  }
  .mat-mdc-menu-panel.mat-menu-panel .mat-mdc-menu-content {
    padding-bottom: 0;
    padding-top: 0;
  }

  .mat-mdc-menu-content.mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text.mat-mdc-menu-item-text {
    font-size: 14px;
    letter-spacing: normal;
  }

  // Page titles
  .mat-mdc-card-title h1 {
    color: $text;
    font-size: 34px;
    font-weight: 300;
    text-transform: uppercase;
  }

  // Set card titles back to the defaults for old angular material
  .mat-mdc-card-title {
    font-size: 24px !important;  
    line-height: normal !important;
    margin-bottom: 8px !important; 
  }
}

// Angular flex-layout replacement for Angular Material
.flex {
  flex: 1 1 auto;
}

.flex-fill {
  flex: 1 1 auto;
  width: 100%;
}

.flex-32px {
  flex: 1 1 32px;
  max-width: 32px;
  min-width: 32px;
}

.flex-50px {
  flex: 1 1 50px;
  max-width: 50px;
  min-width: 50px;
}

.flex-60px {
  flex: 1 1 60px;
  max-width: 60px;
  min-width: 60px;
}

.flex-100px {
  flex: 1 1 100px;
  max-width: 100px;
  min-width: 100px;
}

.flex-200px {
  flex: 1 1 200px;
  max-width: 200px;
  min-width: 200px;
}

.flex-300px {
  flex: 1 1 300px;
  max-width: 300px;
  min-width: 300px;
}

.flex-50 {
  flex: 1 1 50%;
}

.flex-33 {
  flex: 1 1 33%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}



.flex-row-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  // gap: 10px;
}

.flex-row-start-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.flex-row-start-space-between {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
}

.flex-row-end-space-between {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: flex-end;
}

.flex-row-center-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  gap: 20px;
}

.flex-row-wrap-gap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.card__title {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;  
}

.card__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

// Options for dislaying expansion panels without padding
.mat-expansion-panel.no-padding.no-padding {
  .mat-expansion-panel-header {
    padding-left: 0;
  }
  .mat-expansion-panel-body {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Print styling changes */
@media print {
  .mat-app-background {
    background-color: white !important;
    height: auto !important;
  }
  .mat-mdc-card {
    box-shadow: none !important;
  }
}

@include mat.all-component-themes($gps-app-theme);
@include gps-theme($gps-app-theme);

