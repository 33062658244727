/* You can add global styles to this file, and also import other style files */
@import "variables";

html, body { height: 100%; }
body { margin: 0; font-family: "Selawik", "Helvetica Neue", sans-serif; color: $text; }

@font-face {
  font-family: 'Selawik';
  src: url('/assets/fonts/Selawik-Semibold.eot');
  src: url('/assets/fonts/Selawik-Semibold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Selawik-Semibold.woff2') format('woff2'),
      url('/assets/fonts/Selawik-Semibold.woff') format('woff'),
      url('/assets/fonts/Selawik-Semibold.svg#Selawik-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Selawik';
  src: url('/assets/fonts/Selawik-Semilight.eot');
  src: url('/assets/fonts/Selawik-Semilight.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Selawik-Semilight.woff2') format('woff2'),
      url('/assets/fonts/Selawik-Semilight.woff') format('woff'),
      url('/assets/fonts/Selawik-Semilight.svg#Selawik-Semilight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Selawik';
  src: url('/assets/fonts/Selawik-Regular.eot');
  src: url('/assets/fonts/Selawik-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Selawik-Regular.woff2') format('woff2'),
      url('/assets/fonts/Selawik-Regular.woff') format('woff'),
      url('/assets/fonts/Selawik-Regular.svg#Selawik-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Hide Zendesk icon on print */
@media print {
  #webWidget, #launcher
  {
    display: none !important;
  }
}
